import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'kategori',
        path: 'kategori',
        component: () => import('../pages/kategori.vue'),
      },
      {
        name: 'produk',
        path: 'produk',
        component: () => import('../pages/produk.vue'),
      },
      {
        name: 'promo',
        path: 'promo',
        component: () => import('../pages/promo.vue'),
      },
      {
        name: 'cabang',
        path: 'cabang',
        component: () => import('../pages/cabang.vue'),
      },
      {
        name: 'ulasan',
        path: 'ulasan',
        component: () => import('../pages/ulasan.vue'),
      },
    ],
    beforeEnter: (to, from, next) => {
      if (!window.localStorage.getItem('token')) {
        next({
          path: '/auth/login',
        })
      } else {
        next()
      }
    },
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
