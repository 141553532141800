<template>
  <RouterView />
</template>

<script setup>
import axios from 'axios'
import { useGlobalStore } from './stores/global-store'

const GlobalStore = useGlobalStore()

axios.interceptors.request.use(
  function (config) {
    if (config.method == 'get') {
      if (config.params) {
        config.params['iduser'] = window.localStorage.getItem('iduser')
      }
    }

    if (window.localStorage.getItem('iduser') && config.method === 'post') {
      if (config.data instanceof FormData) {
        config.data.append('iduser', window.localStorage.getItem('iduser'))
      } else {
        config.data = {
          ...config.data,
          iduser: window.localStorage.getItem('iduser'),
        }
      }
    }

    return config
  },
  function (error) {
    // Do something with request error
    console.log(error)
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    // console.log(error);
    // $q.loading.hide();
    // $q.notify({
    //   type: 'negative',
    //   message: ParseError(error)
    // })
    GlobalStore.setLoading(false)
    return Promise.reject(error)
  },
)
</script>

<style lang="scss">
@import 'scss/main.scss';

#app {
  font-family: 'Inter', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  min-width: 20rem;
}
</style>
